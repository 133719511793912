import { Ref } from 'vue';
import { useRouter } from 'vue-router';
import { Order, OrderEventNames, ordersServicePlugin } from '@/features/orders';
import { RestageOrderPluginDto, UseRestageOrder } from '../types';
import { restageOrderPluginRegister } from '../helpers';

export function useRestageOrder(): UseRestageOrder {
  const router = useRouter();

  const restage = async (order: Ref<Order | undefined>): Promise<void> => {
    if (order.value === undefined) {
      return;
    }

    const restageOrderPluginDto: RestageOrderPluginDto = {
      order: order.value,
    };

    const restageOrderPluginPipeline = restageOrderPluginRegister();

    await restageOrderPluginPipeline.execute(restageOrderPluginDto);
  };

  const deleteLatestRestagingStartedTrackedEvent = async (
    order: Order,
  ): Promise<void> => {
    await ordersServicePlugin
      .get()
      .deleteLatestTrackedEvent(order, OrderEventNames.restaging_started);
  };

  const goHandoverCustomerInformation = async (order: Order) => {
    await deleteLatestRestagingStartedTrackedEvent(order);

    void router.push({
      name: 'handover-customer-information',
      params: {
        id: order.id,
      },
    });
  };

  return {
    restage,
    deleteLatestRestagingStartedTrackedEvent,
    goHandoverCustomerInformation,
  };
}
